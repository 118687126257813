



























































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";
import { dispatch } from "@/store";

export default Vue.extend({
  computed: {
    ...mapState("assets", {
      images: "images",
      oss: "oss"
    }),
    ...mapState("app", {
      language: "language"
    }),
    pdfList() {
      return {
        pdf_cyberboard: this.$t("cyberboard.name"),
        pdf_cyberboard_r2_r3: this.$t("cyberboardR2.name"),
        pdf_cyberboard_r4: this.$t("cyberboardR4.name"),
        pdf_cyberboard_xmas: this.$t("cyberboardXMAS.name"),
        pdf_cyberboard_terminal: this.$t("cyberboardTerminal.name"),
        pdf_am_neon_80: this.$t("AMNeon80.name"),
        pdf_am_rgb_65: this.$t("AMRGB65.name"),
        pdf_am_relic_80: this.$t("AMRelic80.name"),
        pdf_am_65_less: this.$t("AM65Less.name"),
        pdf_am_65_less_r2: this.$t("AM65LessR2.name"),
        pdf_am_afa: this.$t("AMAfa.name"),
        pdf_am_afa_r2: this.$t("AMAfaR2.name"),
        pdf_am_hatsu: this.$t("AMHatsu.name"),
        pdf_cyberblade: this.$t("cyberblade.name"),
        pdf_cybercharge: this.$t("cybercharge.name"),
        pdf_cybercoin: this.$t("cybercoin.name"),
        pdf_cybermat_r2: this.$t("cybermatR2.name")
      };
    }
  },
  methods: {
    contactUs() {
      dispatch.contactUs();
    },
    onDownload(url: string) {
      download(url);
    }
  }
});
